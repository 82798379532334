<script setup lang="ts">
import type { ComponentQuestionsProps } from "./ComponentQuestions.props"
const props = defineProps<ComponentQuestionsProps>()
const faq = computed(() => [props])
</script>

<template>
  <div class="cms-questions">
    <CustomerServiceFaq v-if="props" :faqs="faq" />
  </div>
</template>
